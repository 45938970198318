import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import SplashTitle from "../components/SplashTitle";
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import ContactUsBar from "../components/ContactUsBar";

export const pageQuery = graphql`
  {
    splashImage: file(relativePath: { eq: "house-pool.jpg" }) {
      ...SplashImage
    }
    site {
      siteMetadata {
        defaultLanguage
      }
    }
  }
`;

export default function LeadsPage({ data }) {
  const intl = useIntl();

  return (
    <Layout
      intl={intl}
      title={intl.formatMessage({ id: "satellite-powered-leads.title", defaultMessage: "Utilizing space data to streamline your workflows" })}
    >
      <Seo
        intl={intl}
        title={intl.formatMessage({ id: "satellite-powered-leads.title", defaultMessage: "Utilizing space data to streamline your workflows" })}
      />
      <SplashTitle
        image={getImage(data.splashImage)}
        subheadline={intl.formatMessage({ id: "satellite-powered-leads.subheadline", defaultMessage: "Low lead conversion" })}
      >
        <FormattedMessage id="satellite-powered-leads.headline" defaultMessage="Utilizing space data to streamline your workflows" />
      </SplashTitle>
      <main>
        <div className="max-w-2xl lg:max-w-7xl mx-auto py-24 sm:px-2 sm:py-32 px-4 lg:px-8">
          <div className="grid grid-cols-1 items-center gap-y-4 md:gap-y-10 gap-x-16 lg:grid-cols-2">
            <h2 className="text-4xl md:text-5xl leading-tight md:leading-snug font-extrabold tracking-tight text-gray-900">
              {intl.formatMessage(
                {
                  id: "satellite-powered-leads.main-section-headline",
                  defaultMessage: "<mark>Outsmart your competition</mark> with highly qualified leads",
                },
                { mark: (text) => <mark>{text}</mark> }
              )}
            </h2>
            <p className="mt-4 text-gray-500 prose-md md:prose-lg">
              <FormattedMessage
                id="satellite-powered-leads.description"
                defaultMessage="State-of-the art satellite imagery can - in combination with other geospatial data - be used to detect and locate properties with specific physical characteristics: land use class, parcel size, reachability, existence of swimming pools, ongoing construction etc. At ubicube we go even further and link the identified properties of interest with national cadastre data to derive qualitative offline marketing and real estate leads that boost your sales numbers."
              />
            </p>
          </div>
          <div className="w-full my-24">
            <h2 className="text-2xl mb-8 md:text-4xl md:mb-12 font-extrabold tracking-tight text-gray-900">
              <FormattedMessage id="satellite-powered-leads.how-does-it-work" defaultMessage="How does it work?" />
            </h2>
            <div className="mt-4 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/satellite_pin.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="satellite-powered-leads.section-1-headline-1" defaultMessage="Geodata-driven Property Search" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage
                      id="satellite-powered-leads.section-1-text-1"
                      defaultMessage="We train our geospatial intelligence to scan satellite imagery and open geodata for properties that fit your search criteria or whose owners are your target customers."
                    />
                  </p>
                </div>
              </div>
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/database_and_map.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="satellite-powered-leads.section-1-headline-2" defaultMessage="Cadastre Data Integration" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage
                      id="satellite-powered-leads.section-1-text-2"
                      defaultMessage="In order to extract qualified leads, we dock to publicly available databases to determine contact points to corresponding property owners."
                    />
                  </p>
                </div>
              </div>
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/screen_and_items.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="satellite-powered-leads.section-1-headline-3" defaultMessage="Convenient Access" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage
                      id="satellite-powered-leads.section-1-text-3"
                      defaultMessage="The generated leads can be provided in various ways: from Excel lists to Interactive Web Portals, we offer full flexibility."
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full my-24"></div>
          <div>
            <h2 className="text-2xl mb-8 md:text-4xl md:mb-12 font-extrabold tracking-tight text-gray-900">
              <FormattedMessage
                id="satellite-powered-leads.headline-benefits"
                defaultMessage="Your <mark>benefits</mark>"
                values={{ mark: (text) => <mark>{text}</mark> }}
              />
            </h2>

            <div className="mt-4 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/arrow_down.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="satellite-powered-leads.section-2-headline-1" defaultMessage="Reduce Marketing Efforts" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage
                      id="satellite-powered-leads.section-2-text-1"
                      defaultMessage="Highly targeted leads spare the resources of you and your employees."
                    />
                  </p>
                </div>
              </div>
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/arrow_up.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="satellite-powered-leads.section-2-headline-2" defaultMessage="Increase Conversion Rates" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage
                      id="satellite-powered-leads.section-2-text-2"
                      defaultMessage="Achieve better conversion rates with less effort."
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white max-w-2xl lg:max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8 xl:mb-32">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <h2 className="text-3xl font-extrabold text-gray-900">
                <FormattedMessage
                  id="satellite-powered-leads.use-cases-headline"
                  defaultMessage="<mark>Use-cases</mark>"
                  values={{ mark: (text) => <mark>{text}</mark> }}
                />
              </h2>
            </div>
            <div className="mt-12 lg:mt-0 lg:col-span-2">
              <dl className="space-y-12">
                <div>
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    <FormattedMessage
                      id="satellite-powered-leads.use-case-1"
                      defaultMessage="Offline Marketing Leads for Real Estate Related Service and Trading Companies"
                    />
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    <FormattedMessage
                      id="satellite-powered-leads.use-case-1-text"
                      defaultMessage="Private properties with features such as ongoing construction, pools or gardens represent a business opportunity for a lot of companies! Ubicube uses geospatial intelligence to enable them to target their marketing campaigns on such properties and their corresponding owners."
                    />
                  </dd>
                </div>
                <div>
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    <FormattedMessage id="satellite-powered-leads.use-case-2" defaultMessage="Off-market Property Leads for Real Estate Agents" />
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    <FormattedMessage
                      id="satellite-powered-leads.use-case-2-text"
                      defaultMessage="Real estate agents now have the possibility to systematically search for properties and rank the results according to their clients’ needs. Ubicube develops the technological backbone and enables their clients to finally get a hand on off-market properties."
                    />
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div className="max-w-screen-2xl mx-auto my-20">
          <ContactUsBar intl={intl} />
        </div>
      </main>
    </Layout>
  );
}
